import React from 'react';
import { Box, Typography, Modal, Button, IconButton, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

interface PrivacyPolicyModalProps {
    open: boolean;
    handleClose: () => void;
}

const PrivacyPolicyModal: React.FC<PrivacyPolicyModalProps> = ({ open, handleClose }) => {
    const theme = useTheme();
    const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="privacy-policy-title"
            aria-describedby="privacy-policy-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: isMobileOrTablet ? '90%' : '80%',
                maxHeight: '80vh',
                overflowY: 'auto',
                bgcolor: 'background.paper',
                boxShadow: 24,
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column',
            }}>

                {/* Cabecera del modal */}
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    bgcolor: 'primary.main',
                    color: 'white',
                    p: 2,
                }}>
                    <Typography id="privacy-policy-title" variant="h6" component="h2">
                        Políticas de SushiGi
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{ color: 'white' }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                {/* Contenido del modal */}
                <Box sx={{ p: 3, flexGrow: 1 }}>
                    <Typography id="privacy-policy-description">
                        <section>
                            <h2>Política de Privacidad</h2>
                            <p>
                                El responsable del tratamiento de los datos de identificación personal recogidos a través de la web
                                a la que se accede desde el dominio <strong>sushigi.com</strong> es <strong>Terrasuyi, S.L.</strong> con
                                <strong> CIF B-88027701</strong> y domicilio en C/ Juan Carlos I, nº 13, local 19, 28806 Alcalá de Henares, Madrid, España.
                            </p>
                            <p>
                                Todos los datos facilitados por los usuarios como consecuencia de la utilización y/o solicitud de los servicios ofrecidos
                                por <strong>Terrasuyi, S.L.</strong> a través de la web serán objeto de tratamiento automatizado e incorporados a los correspondientes
                                ficheros cuyo responsable es <strong>Terrasuyi, S.L.</strong>. Los usuarios que faciliten datos de carácter personal, consienten
                                inequívocamente la incorporación de los datos facilitados a dichos ficheros automatizados y al tratamiento informatizado
                                o no de los mismos con fines de publicidad y de prospección comercial.
                            </p>
                            <p>
                                Al facilitarnos su teléfono, nos estará autorizando expresamente a que le remitamos comunicaciones publicitarias o promocionales
                                por el medio de contacto facilitado, conforme a lo establecido en el <strong>artículo 21 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información</strong>.
                                Para la cancelación de las comunicaciones electrónicas, por favor contacte con el responsable del sitio.
                            </p>
                            <p>
                                <strong>Terrasuyi, S.L.</strong> se compromete a no recabar información innecesaria sobre sus usuarios, indicándoles en el momento de proceder a la recogida de los datos el carácter obligatorio o facultativo de las respuestas
                                a las cuestiones que le sean planteadas; a tratar con extrema diligencia la información personal que le puedan facilitar los usuarios; y a cumplir en cualquier fase del tratamiento
                                con la obligación de guardar secreto respecto de los datos facilitados por éstos.</p>
                            <p>
                                <strong>Terrasuyi, S.L.</strong> se reserva el derecho a modificar la Política de Privacidad, siempre en los términos permitidos por la legislación española vigente y previa comunicación a los interesados,
                                bien mediante publicación en esta página o por cualquier otro medio de comunicación o difusión que se considere oportuno.
                            </p>
                        </section>

                        <section>
                            <h2>Política de Tratamiento de Alimentos</h2>
                            <p>
                                En <strong>Terrasuyi, S.L.</strong>, cumplimos con todas las normativas y regulaciones vigentes en materia de seguridad alimentaria y manipulación de alimentos, conforme a la Ley 17/2011 y el Reglamento (CE) Nº 852/2004.
                            </p>
                            <p>Nuestros procesos siguen estrictas medidas de control para garantizar la seguridad y calidad de los alimentos:</p>
                            <ul>
                                <li>Instalaciones certificadas que cumplen con los requisitos de higiene y seguridad.</li>
                                <li>Personal con Certificado de Manipulador de Alimentos.</li>
                                <li>Mantenimiento de la cadena de frío en todo el proceso de entrega.</li>
                                <li>Controles periódicos de calidad y trazabilidad.</li>
                                <li>Información clara sobre alérgenos según el Reglamento (UE) Nº 1169/2011.</li>
                            </ul>
                            <p>
                                Para consultas sobre nuestras políticas de seguridad alimentaria, contacta en <a href="mailto:sushigi.catering@gmail.com">sushigi.catering@gmail.com</a> o por escrito a:
                                C/ Juan Carlos I, nº 13, local 19, 28806 Alcalá de Henares, Madrid, España.
                            </p>
                        </section>
                    </Typography>
                </Box>

                {/* Footer del modal */}
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 2,
                    bgcolor: 'grey.100',
                    borderBottomLeftRadius: '10px',
                    borderBottomRightRadius: '10px',
                }}>
                    <Button onClick={handleClose} variant="contained" color="primary" style={{ minWidth: '25%' }}>
                        Cerrar
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default PrivacyPolicyModal;
